<template>
  <el-dialog
    :title="'文件上传'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="handleCancle"
  >
    <div>
      <el-form
        :model="form"
        label-width="1px"
        ref="dataForm"
        v-loading="formLoading"
      >
        <el-form-item>
          <div class="block">
            <el-cascader :options="projectOptions"
                         :props="levelData"
                         v-model="form.level"
                         placeholder="单选选择任意一级选项"
                         clearable
                         ref="cascade"
            ></el-cascader>
          </div>
        </el-form-item>
        <el-upload
          ref="upload"
          class="upload-demo"
          action="void"
          :auto-upload="false"
          :on-change="changeHanle"
          :on-remove="handleRemove"
          :file-list="this.fileLists"
          multiple
        >
          <el-button size="small" type="primary">点击选择文件</el-button>
          <div slot="tip" class="el-upload__tip">请确保文件的大小小于100MB</div>
        </el-upload>

        <el-button class="submit" type="primary" @click="submitFiles()"
          >提交</el-button
        >
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { getUUID } from "@/utils";
export default {
  props: ["dirs"],
  data() {
    const self = this
    return {
      id: 1,
      projectOptions: [],
      levelData: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          self.getSecond(node, resolve);
        }
      },
      formLoading: false,
      instance: "",
      // lastFile: {},
      currOperator: "",
      currOperatorName: "",
      visible: false,
      deviceOption: [],
      form: {
        fileId: "",
        fileName: "",
        bucket: "",
        fileDir: "",
        prefix: "",
        level: {},
      },
      //批量写入mysql的文件映射记录
      // batchArrays: [],
      // coalOption:[],
      // systemOption: [],
      // subSystemOption: [],
      // subDeviceOption: [],
      uploadParams: {},
      fileLists: [],
    };
  },
  computed: {
    bucket() {
      if (this.currOperator == 1) {
        //如果为管理员，接受从父组件传来的dirs
        let num = this.dirs.indexOf("/");
        return this.dirs.substring(0, num);
      } else {
        //普通用户名字作为桶名
        // return this.currOperatorName;
        return `user-directory${this.currOperator}`
      }
    },
    perfix() {
      let num = this.dirs.indexOf("/");
      return this.dirs.substring(num + 1, this.dirs.length);
    },
  },
  created() {
    this.currOperator = JSON.parse(
      window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
      window.sessionStorage.getItem("user")
    ).username;
    console.log(this.currOperator);
  },
  methods: {
    //初始化数据
    init(dirs) {
      // if (dirs.split("/").length <= 2) {
      //   this.dirs = dirs.substring(0, dirs.length - 1);
      // } else {
      //   this.dirs = dirs;
      // }
      //拿到所有项目数据
      this.getProjects();
    },
    //对话框关闭函数
    handleCancle(){
      this.form={
        fileId: "",
            fileName: "",
            bucket: "",
            fileDir: "",
            prefix: "",
            level: {},
      },
      //不再转圈圈
      this.formLoading = false;

    },
    getSecond(node, resolve) {
      //叶子节点不进行搜索
      if(node.data.leaf===true){
        resolve([])
        return
      }
      //如果是点击project进来的，搜索第二层级
      if(node.data.project===true){
        let nodes=[];
        this.getRequest("/universaltable/getRootAllItem?id=" + node.data.value).then(response => {
          let data =response.data.data;
          for(let i=0;i<data.length;i++)
          {
            let o={};
            o.label=data[i].name;
            o.value=data[i].id;
            o.leaf=data[i].is_leaf;
            o.levelId=response.data.levelId
            nodes.push(o);
          }
        }).then(()=>{
          resolve(nodes);
        })
      }
      //其他搜索第三层级
      else{
        console.log(node.data)
        let nodes=[];
        this.getRequest("/universaltable/getOtherAllItem?id=" + node.data.value+"&tableId="+node.data.levelId).then(response => {
          let data =response.data.data;
          for(let i=0;i<data.length;i++)
          {
            let o={};
            o.label=data[i].name;
            o.value=data[i].id;
            o.leaf=data[i].is_leaf;
            o.levelId=response.data.levelId
            nodes.push(o);
          }
        }).then(()=>{
          resolve(nodes);
        })
      }
    },
    getProjects(){
      this.postRequest("/levelManage/projectattributes/getAllProject").then(response => {
        this.projectOptions=[];
        let data=response.data.data
        for(let i=0;i<data.length;i++)
        {
          let o={};
          o.label=data[i].name;
          o.value=data[i].id;
          if(data[i].disabled==0)
            o.leaf=true;
          else o.leaf=false;
          o.project=true
          this.projectOptions.push(o);
        }
        this.visible = true;
      })
    },

    //暂存文件
    changeHanle(file, fileList) {
      //判断文件大小是否可以存储
      if (file.size / (1024*1024) > 100) {
        this.$message({
          message: file.name + "文件大小过大,无法上传",
          type: "error",
        });
        fileList.splice(-1, 1);
        console.log(fileList)
      }
      //判断是否有重名文件
      for (let i = 0; i < fileList.length-1; i++){
        if (file.name === fileList[i].name) {
          this.$message({
            message: "本次上传中存在同名文件" + file.name,
            type: "error",
          });
          fileList.splice(-1, 1);
        }
      }
      this.fileLists=fileList
    },

    /**     移除上传文件    **/
    handleRemove(file,fileList) {
      this.$refs.upload.abort(); //取消上传
      this.fileLists=fileList
      this.$message({ message: "成功移除" + file.name, type: "success" });
    },

    // 提交 将文件上传到minio中，将映射关系存到mysql中
    submitFiles() {
      if(this.form.level.length===undefined){
        this.$message.error("请先选择层级")
        return;
      }
      if(this.fileLists.length===0){
        this.$message.error("请先选择文件")
        return;
      }
      this.formLoading = true;
      //为了报错与删除，每一个文件单独上传算了
      for(var i=this.fileLists.length-1;i>=0;i--){
        let file=this.fileLists[i];
        let formData = new FormData();
        formData.append("uploadfile", file.raw)
        formData.append("bucket", this.bucket);
        formData.append("dirname", this.perfix);
        //上传文件
        this.postFilesRequest("/minio/uploadfile", formData)
            .then(({data})=>{
              //上传成功,开始保存到mysql
              if (data.status === 200) {
                console.log("eneter")
                let fileInfo = {};
                fileInfo.bucket = this.bucket
                fileInfo.fileDir = this.perfix
                fileInfo.fileId = getUUID();
                fileInfo.fileName = file.name
                fileInfo.levelId = this.form.level
                this.$http({
                  url: this.$http.adornUrl(`/minio/saveOne`),
                  method: "post",
                  data: fileInfo,
                }).then(({data})=>{
                  if(data.msg==="success"){
                    //在文件列表里面删除上传成功的文件
                    this.fileLists.splice(i,1);
                    this.$emit('refreshDataList')
                  }
                })
              }
              //失败和成功的提示接口里面都写了
            })
      }
      //循环完成，关闭loading
      this.formLoading=false;
    },
  },
};
</script>

<style lang="scss" scoped>
.submit {
  margin-top: 30px;
  margin-left: 460px;
}
</style>
>
