<template>
  <div class="select-container">
    <!-- 文件展示模式 -->
    <i
      class="model-icon el-icon-s-order"
      :class="{ active: fileGroupLable === 0 }"
      title="列表模式"
      @click="handleFileDisplayModelChange(0)"
    ></i>
    <i
      class="model-icon el-icon-s-grid"
      :class="{ active: fileGroupLable === 1 }"
      title="网格模式"
      @click="handleFileDisplayModelChange(1)"
    ></i>
    <!-- <el-divider direction="vertical"></el-divider> -->
  </div>
</template>

<script>
export default {
  name: "selectDeviceField",
  data() {
    return {
      fileGroupLable: 0, //  文件展示模式
    };
  },
  methods: {
    /**
     * 文件查看模式切换
     * @param {number} label 0 列表 1 网格
     */
    handleFileDisplayModelChange(label) {
      console.log(label);
      this.fileGroupLable = label;
      this.$store.commit("file/changeFileModel", label);
      // this.$emit("getTableDataByType");
    },
  },
};
</script>

<style lang="scss" scoped>
$SecondaryText: #909399;
$Primary: #409eff;
.select-container {
  display: inline-block;
  margin-left: 372px;
}
.model-icon {
  margin-left: 8px;
}
.model-icon.active {
  color: $Primary;
}
.model-icon {
  font-size: 20px;
  cursor: pointer;
  color: $SecondaryText;

  &:hover {
    color: $Primary;
  }
}
</style>