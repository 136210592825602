<template>
  <!-- 文件平铺 -->
  <div class="file-grid-wrapper">
    <ul
      class="file-list"
      v-loading="loading"
      element-loading-text="文件加载中……"
    >
      <li
        class="file-item"
        v-for="(item, index) in fileList"
        :key="index"
        :title="item.pathSuffix"
        :style="`width: ${gridSize + 40}px; `"
        
      >
        <img
          class="file-img"
          :src="$file.setFileImg(item)"
          :style="`width: ${gridSize}px; height: ${gridSize}px;`"
          @dblclick="$parent.next(item)"
        />
        <div class="file-name">{{item.pathSuffix}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FileGrid",
  props: {
    fileList: Array, //  文件列表
    loading: Boolean,
  },
  data() {
    return {
      fileListSorted: [],
      officeFileType: ["ppt", "pptx", "doc", "docx", "xls", "xlsx"],
      selectedFile: {},
    };
  },
  computed: {
    /**
     * gridSize: 图标大小
     *  */
    ...mapGetters(["gridSize"]),

    // 屏幕宽度
    screenWidth() {
      return this.$store.state.common.screenWidth;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// 背景色
$tabBackColor :#F5F7FA;
$BorderBase : #DCDFE6;
.file-grid-wrapper {
  border-top: 1px solid $BorderBase;

  .file-list {
    height: calc(100vh - 206px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    list-style: none;

    .file-item {
      margin: 0 16px 16px 0;
      position: relative;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      z-index: 1;

      &:hover {
        background: $tabBackColor;
        // background: #E8F9FD;

        .file-name {
          font-weight: 550;
        }
      }

      .file-name {
        margin-top: 8px;
        height: 44px;
        line-height: 22px;
        font-size: 12px;
        word-break: break-all;
      }

      .file-checked-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: rgba(245, 247, 250, 0.5);
        width: 100%;
        height: 100%;

        .file-checked {
          position: absolute;
          top: 16px;
          left: 24px;
        }
      }

      .file-checked-wrapper.checked {
        background: rgba(245, 247, 250, 0);
      }
    }
    .file-item.active {
      background: pink;
    }
  }

  .right-menu-list {
    position: fixed;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    padding: 4px 0;
    color: sandybrown;

    .right-menu-item,
    .unzip-item {
      padding: 0 16px;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      &:hover {
        background: sandybrown;
        color: #000;
      }
      i {
        margin-right: 8px;
      }
    }
  }
  .right-menu-list,
  .unzip-list {
    background: #fff;
    border: 1px solid;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    padding: 4px 0;
    color: sandybrown;
  }
}
</style>
