<template>
  <div class="breadcrumb-wrapper">
    <!-- <div class="title">当前位置：</div> -->
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div
          @click="getQuery('')"
          class="able-input"
          style="display:inline"
          v-show="currUser == 1"
        >
          <el-breadcrumb-item> 全部文件</el-breadcrumb-item><span></span>
        </div>
        <div
          class="able-input"
          tabindex="1"
          v-for="(item, index) in breadCrumbList"
          :key="index"
          @click="getQuery(item)"
          style="display:inline"
        >
          <el-breadcrumb-item>{{ item.name }}</el-breadcrumb-item>
          <span></span>
        </div>

      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    // 文件路径
    filePath: {
      require: true,
      type: String,
    },
    //当前操作用户ID
    currUser: {
      require: true,
      type: Number,
    },
  },
  data() {
    return {
      inputFilePath: "", //  路径输入
    };
  },
  computed: {
    /**
     * 面包屑导航栏数组
     */
    breadCrumbList: {
      get() {
        let filePath = this.filePath;
        let filePathList = filePath ? filePath.split("/") : [];
        let res = []; //  返回结果数组
        let _path = []; //  存放祖先路径
        for (let i = 0; i < filePathList.length; i++) {
          if (filePathList[i]) {
            _path.push(filePathList[i]);
            res.push({
              path: _path.join("/") + "/",
              name: filePathList[i],
            });
          }
        }
        console.log(res);
        return res;
      },
      set() {
        return [];
      },
    },
  },
  methods: {
    /**
     * 路径输入框失去焦点或用户按下回车时触发
     */
    handleInputBlurEnter() {
      if (this.inputFilePath !== this.filePath) {
        const fixInputFilePath = this.inputFilePath.endsWith("/")
          ? this.inputFilePath
          : this.inputFilePath + "/";
        this.$parent.listAllFileByDir(fixInputFilePath);
      }
    },
    // 获取文件参数
    getQuery(item) {
      console.log(item);
      // return { query: { filePath: item.path} }
      item == ""
        ? this.$parent.listAllBuckets()
        : this.$parent.listAllFileByDir(item.path);
    },
  },
};
</script>
<style >
.breadcrumb-wrapper {
  padding: 0;
  height: 30px;
  line-height: 30px;
  display: flex;
}
.breadcrumb-wrapper .title {
  width: 75px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.breadcrumb-wrapper >>> .el-breadcrumb {
  height: 30px;
  line-height: 30px;
}
.el-breadcrumb {
  padding: 0px 0 0px 10px;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
}
.breadcrumb-box {
  padding: 0 8px;
  flex: 1;
  display: flex;
}
.breadcrumb-box .able-input {
  cursor: pointer;
}

.breadcrumb-box .able-input:focus {
  font-weight: bolder;
}
</style>


