<template>
  <div>
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-button round @click="back">返回上一级</el-button>
      </el-form-item>
      <el-form-item> </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="uploadHandle()"
          :disabled="nowdirectory == '' && this.currOperator == 1"
          ><i class="el-icon-upload"></i> 上传文件</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="newFolderHandle"
          :disabled="nowdirectory == '' && this.currOperator == 1"
        >
          <i class="el-icon-folder-add"></i> 新建文件夹</el-button
        >
      </el-form-item>

      <span style="font-size: 10px">搜索：</span>

<!--      <el-cascader-->
<!--        separator=" | "-->
<!--        style="width: 500px"-->
<!--        :props="props"-->
<!--        placeholder="请选择附属设备搜索文件"-->
<!--        clearable-->
<!--        ref="cascade"-->
<!--        filterable-->
<!--        @change="handleSearch()"-->
<!--      ></el-cascader>-->
      <el-cascader :options="projectOptions"
                   :props="levelData"
                   v-model="level"
                   placeholder="单选选择任意一级选项"
                   clearable
                   ref="cascade"
                    @change="handleSearch()"
                   :disabled="nowdirectory.length <1 "
      ></el-cascader>
      <el-select
        v-model="selectType"
        placeholder="请选择文件类型"
        @change="handleSearchByType()"
        clearable
        style="margin-left: 15px"
        :disabled="nowdirectory.length <1 "
        @clear="clearTypeSelect"
      >
        <el-option
          v-for="item in fileTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <selectFileModel></selectFileModel>
    </el-form>
    <!-- 选择查看方式按钮 -->

    <breadCrumb
      :filePath="nowdirectory"
      :currUser="currOperator"
    ></breadCrumb>
    <!-- 文件列表-表格模式 -->
    <FileTable
      :fileList="dataList"
      :loading.sync="dataListLoading"
      v-if="fileModel === 0"
    ></FileTable>
    <!-- 文件列表-网格模式 -->
    <FileGrid
      :fileList="dataList"
      :loading="dataListLoading"
      v-if="fileModel === 1"
    ></FileGrid>
    <!-- 上传文件对话框 -->
    <UploadFileMapping
      v-if="UploadFileMappingFlag"
      ref="uploadFileMapping"
      @refreshDataList="allfile"
      :dirs.sync="nowdirectory"
    ></UploadFileMapping>
    <!-- 新建文件夹对话框 -->
    <newFoldeDialog
      v-if="NewFoldeFlag"
      ref="newFoldeDialog"
      @refreshDataList="allfile"
    ></newFoldeDialog>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import UploadFileMapping from "./uploadFileMapping.vue";
import newFoldeDialog from "./newFoler.vue";
import breadCrumb from "./BreadCrumb.vue";
import selectFileModel from "./selectFileModel.vue";
import FileTable from "./FileTable.vue";
import FileGrid from "./FileGrid.vue";

import dayjs from "dayjs";
export default {
  computed: {
    averageUseDir() {
      let num = this.nowdirectory.indexOf("/");
      return this.nowdirectory.substring(num + 1, this.nowdirectory.length);
    },
    // // 当前所在路径
    // filePath() {
    //   console.log("路径变了");
    //   return this.$route.query.filePath ? this.$route.query.filePath : "";
    // },
    fileModel() {
      return this.$store.getters.fileModel;
    },
  },
  watch: {
    // 监听文件查看模式
    fileModel() {
      this.setPageCount();
    },
  },
  data() {
    let that = this;
    return {
      projectOptions: [],
      levelData: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          that.getSecond(node, resolve);
        }
      },
      level: [],
      currOperator: 1,
      currOperatorName: "admin",
      UploadFileMappingFlag: false,
      NewFoldeFlag: false,
      dataListLoading: false,
      dataForm: {},
      dataList: [],
      originData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      fileTypeOptions: [
        {
          value: "video",
          label: "视频",
        },
        {
          value: "picture",
          label: "图片",
        },
        {
          value: "file",
          label: "文件",
        },
      ],
      selectType: "",
      nowdirectory: "",
      // props: {
      //   //级联选择器懒加载
      //   lazy: true,
      //   lazyLoad(node, resolve) {
      //     const { level } = node;
      //     console.log(level, node);
      //     if (level == 0) {
      //       that.getAllCoal((list1) => {
      //         let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
      //         resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
      //       });
      //     }
      //     if (level == 1) {
      //       that.getSystem(2, (list2) => {
      //         // console.log(list2);
      //         if (list2[0] == null) {
      //           let arr = list2.map((e) => ({ value: 0, label: 0 }));
      //           resolve(arr);
      //         } else {
      //           let arr = list2.map((e) => ({ value: e.id, label: e.name }));
      //           resolve(arr);
      //         }
      //       });
      //     }
      //     if (level == 2) {
      //       let { value } = node;
      //       console.log(value);
      //       that.getSubSystem(value, (list3) => {
      //         // console.log(list3);
      //         if (list3[0] == null) {
      //           let arr = list3.map((e) => ({ value: e.id, label: e.name }));
      //           resolve(arr);
      //         } else {
      //           let arr = list3.map((e) => ({ value: e.id, label: e.name }));
      //           resolve(arr);
      //         }
      //       });
      //     }
      //     if (level == 3) {
      //       let { value } = node;
      //       // console.log(value);
      //       that.getEquipment(value, (list4) => {
      //         // console.log(list4);
      //         if (list4[0] == null) {
      //           let arr = list4.map((e) => ({ value: e.id, label: e.name }));
      //           resolve(arr);
      //         } else {
      //           let arr = list4.map((e) => ({ value: e.id, label: e.name }));
      //           resolve(arr);
      //         }
      //       });
      //     }
      //     if (level == 4) {
      //       let { value } = node;
      //       // console.log(value);
      //       that.getSubEquipment(value, (list5) => {
      //         // console.log(list5);
      //         if (list5[0] == null) {
      //           let arr = list5.map((e) => ({
      //             value: e.id,
      //             label: e.name,
      //             leaf: true,
      //           }));
      //           resolve(arr);
      //         } else {
      //           let arr = list5.map((e) => ({
      //             value: e.id,
      //             label: e.name,
      //             leaf: true,
      //           }));
      //           resolve(arr);
      //         }
      //       });
      //     }
      //   },
      // },
    };
  },
  created() {
    this.currOperator = JSON.parse(
      window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
      window.sessionStorage.getItem("user")
    ).username;
    console.log(this.currOperator);
  },

  components: {
    //上传文件对话框
    UploadFileMapping,
    //新建文件夹对话框
    newFoldeDialog,
    //面包屑组件
    breadCrumb,
    //选择查看模式按钮
    selectFileModel,
    //列表列表
    FileTable,
    //网格显示
    FileGrid,
  },
  mounted() {
    console.log(this.fileModel);
    this.getProjects();
    this.listAllBuckets();
    this.$bus.$on("newFolderHandle", (val) => {
      this.nowdirectory = this.nowdirectory + val + "/";
      this.dataList = [];
    });
    this.$once("hook:beforeDestroy", () => {
      this.$bus.$off("newFolderHandle");
    });
  },
  methods: {
    //获取第一层
    getProjects(){
      this.postRequest("/levelManage/projectattributes/getAllProject").then(response => {
        this.projectOptions=[];
        let data=response.data.data
        for(let i=0;i<data.length;i++)
        {
          let o={};
          o.label=data[i].name;
          o.value=data[i].id;
          if(data[i].disabled==0)
            o.leaf=true;
          else o.leaf=false;
          o.project=true
          this.projectOptions.push(o);
        }
        this.visible = true;
      })
    },
    //获取后面的
    getSecond(node, resolve) {
      //叶子节点不进行搜索
      if(node.data.leaf===true){
        resolve([])
        return
      }
      //如果是点击project进来的，搜索第二层级
      if(node.data.project===true){
        let nodes=[];
        this.getRequest("/universaltable/getRootAllItem?id=" + node.data.value).then(response => {
          let data =response.data.data;
          for(let i=0;i<data.length;i++)
          {
            let o={};
            o.label=data[i].name;
            o.value=data[i].id;
            o.leaf=data[i].is_leaf;
            o.levelId=response.data.levelId
            nodes.push(o);
          }
        }).then(()=>{
          resolve(nodes);
        })
      }
      //其他搜索第三层级
      else{
        console.log(node.data)
        let nodes=[];
        this.getRequest("/universaltable/getOtherAllItem?id=" + node.data.value+"&tableId="+node.data.levelId).then(response => {
          let data =response.data.data;
          for(let i=0;i<data.length;i++)
          {
            let o={};
            o.label=data[i].name;
            o.value=data[i].id;
            o.leaf=data[i].is_leaf;
            o.levelId=response.data.levelId
            nodes.push(o);
          }
        }).then(()=>{
          resolve(nodes);
        })
      }
    },
    // 获取数据列表,管理员和普通用户展示不同，在后端处理
    listAllBuckets() {
      this.dataListLoading = true;
      if (this.currOperator == 1) {
        this.nowdirectory = "";
        this.$http({
          url: this.$http.adornUrl(
            `/minio/listAllBuckets?pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`
          ),
          method: "get",
          params: this.$http.adornParams(),
        })
          .then(({ data }) => {
            if (data.status == 200) {
              let { data: res, total } = data.obj;
              this.originData = res;
              this.dataList = res;
              this.totalPage = total;
            } else {
              this.$message.error(data.msg);
            }
            this.dataListLoading = false;
          })
          .catch(() => {
            this.dataListLoading = false;
          });
      } else {
        //查询该用户目录下的文件
        // this.nowdirectory = "user-directory" + this.currOperator + this.currOperatorName + "/";
        this.nowdirectory = `user-directory${this.currOperator}_${this.currOperatorName}/`;
        this.listAllFileByDir("user-directory" + this.currOperator);
      }
    },
    /**
     * 表格数据获取相关事件 | 调整分页大小
     */
    setPageCount() {
      this.pageIndex = 1;
      if (this.fileModel === 0) {
        this.pageSize = 10;
      }
      if (this.fileModel === 1) {
        this.pageSize = 20;
      }
      this.allfile();
    },
    //查找当前目录下文件
    //修改了接口，在后端直接进行类型和字段的查询
    listAllFileByDir(nowdirectory) {
      //子组件将值传递给父组件，这样才会导致给子组件的filePath变化，从而影响面包屑的变化
      this.dataListLoading = true;
      // 没有/，则直接按照桶名查询
      if (nowdirectory.indexOf("/") == -1) {
        var str = `/minio/queryFiles?bucket=${nowdirectory}&prefix=&recursive=false&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}&type=${this.selectType}`;
      }
      //有一个/，去掉/，按桶名查询
      else if (nowdirectory.split("/").length - 1 == 1) {
        let bucketName = nowdirectory.substring(0, nowdirectory.length - 1);
        let userNameFlagPos =  bucketName.indexOf("_")
        if (userNameFlagPos !== -1) {
          bucketName = bucketName.substring(0, bucketName.indexOf("_"))
          console.log("bucketName", bucketName.substring(0, bucketName.indexOf("_")))
        }
        str = `/minio/queryFiles?bucket=${bucketName}&prefix=&recursive=false&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}&type=${this.selectType}`;
      }
      // "/"个数超过一个，分桶名和前缀名查询
      else {
        let num = nowdirectory.indexOf("/");
        let bucketName = nowdirectory.substring(0, num);
        let userNameFlagPos =  bucketName.indexOf("_")
        if (userNameFlagPos !== -1) {
          bucketName = bucketName.substring(0, bucketName.indexOf("_"))
        }
        let perfix = nowdirectory.substring(num + 1, nowdirectory.length);
        str = `/minio/queryFiles?bucket=${bucketName}&prefix=${perfix}&recursive=false&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}&type=${this.selectType}`;
      }
      this.$http({
        url: this.$http.adornUrl(str),
        method: "post",
        data: this.level,
      })
        .then(({ data }) => {
          if (data.status == 200) {
            console.log("200")
            let { data: res, total } = data.obj;
            this.originData = res;
            this.dataList = res;
            this.totalPage = total;
            console.log(res)
            console.log(total)
            this.dataListLoading = false;
          } else {
            this.$message.error(data.msg);
            this.dataListLoading = false;
          }
        })
        .catch(() => {});
    },
    //返回上一级
    back() {
      if (this.nowdirectory.split("/").length <= 2) {
        this.nowdirectory = "";
      } else {
        let temp = this.nowdirectory.slice(
          0,
          this.nowdirectory.lastIndexOf("/")
        );
        this.nowdirectory = temp.slice(0, temp.lastIndexOf("/") + 1);
      }

      this.allfile();
    },

    //进入下一级
    next(dire) {
      console.log(dire);
      //如果为文件则直接返回
      if (dire.directory == false) return;
      if (dire.pathSuffix.indexOf("/") == -1) {
        var dir = dire.pathSuffix + "/";
      } else {
        dir = dire.pathSuffix;
      }
      this.pageIndex = 1;
      this.nowdirectory = `${this.nowdirectory}${dir}`;
      console.log("subToParent:", this.nowdirectory);
      this.allfile();
    },

    //下载文件
    downloadHandle(row) {
      console.log(row);
      this.$confirm(`确定对文件[${row.originPath}]进行[下载]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(
              `/minio/getPresignedObjectUrl?bucket=${row.bucketName}&objectName=${row.originPath}`
            ),
            method: "get",
            responseType: "blob"
          }).then((res) => {
            console.log(res);
            const downloadLink = document.createElement('a')
            // 为blob对象创建一个本地URL
            const url = window.URL.createObjectURL(res.data)
            // 将url赋值给其href属性
            downloadLink.href = url
            // 将文件名给到download属性
            downloadLink.setAttribute('download', row.originPath)
            // 将a标签添加到文档中
            document.body.appendChild(downloadLink)
            // 点击下载文件
            downloadLink.click()
            // 从文档中移除a标签
            document.body.removeChild(downloadLink)
            // this.$message({
            //   message: "文件下载成功",
            //   type: "success",
            //   duration: 1500,
            // });
          });
        })
        .catch(() => {});
    },
    // newFolderHandle(name){
    //   var temp = {
    //       pathSuffix: name,
    //       originPath: name,
    //       bucketName: name,
    //       modificationTime:dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //       directory: true,
    //   }
    //   this.dataList.push(temp);
    // },
    //上传文件
    uploadHandle() {
      // this.manualEntryFieldMappingId = id;
      //console.log(this.manualEntryId);
      this.UploadFileMappingFlag = true;
      // console.log(this.nowdirectory);
      this.$nextTick(() => {
        this.$refs.uploadFileMapping.init(this.nowdirectory);
      });
    },
    //新建文件夹
    newFolderHandle() {
      this.NewFoldeFlag = true;
      this.$nextTick(() => {
        this.$refs.newFoldeDialog.init(this.nowdirectory);
      });
    },

    // 删除
    deleteHandle(row) {
      var requestUrl;
      if (row.parentId == 0) {
        //删除桶
        requestUrl = `/minio/deleteBucket?bucket=${row.pathSuffix}`;
      } else if (row.directory == true) {
        //删除文件夹
        requestUrl = `/minio/deleteFiles?bucket=${row.bucketName}&prefix=${row.originPath}&recursive=true`;
      } else {
        //删除文件
        requestUrl = `/minio/deleteFile?bucket=${row.bucketName}&objectName=${row.originPath}`;
      }
      this.$confirm(`确定对[${row.originPath}]进行[删除]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(requestUrl),
            method: "get",
          }).then(({ data }) => {
            console.log(data);
            if (data && data.status === 200) {
              //将mysql中存在的映射也删除
              var p = this.$http({
                url: this.$http.adornUrl(`/minio/delete`),
                method: "delete",
                data: row,
              });
              return p;
            }
          });
        })
        .then((res) => {
          console.log(res);
          // if (data.status == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.allfile();
            },
          });
          // }
        })
        .catch(() => {});
    },

    //级联搜索
    handleSearch() {
      this.pageIndex = 1
      this.allfile()
    },
    getFileType(file) {
      let type = file.pathSuffix.split(".")[1];
      if (file.directory == true) {
        return "目录";
      } else if (["jpg", "png", "svg", "JPG", "PNG", "SVG"].includes(type)) {
        return "图片";
      } else if (["avi", "wmv", "mpeg", "mp4"].includes(type)) {
        return "视频";
      } else {
        return "文件";
      }
    },
    //根据文件类型搜索
    handleSearchByType() {
      // let list = this.originData.filter((file) => {
      //   return this.getFileType(file) == this.selectType;
      // });
      // this.dataList = treeDataTranslate(list, "id");
      this.pageIndex = 1
      this.allfile()
      this.dataListLoading = false;
    },
    //清空时显示所有文件
    clearTypeSelect() {
      // this.dataList = this.originData;
      this.pageIndex = 1
      this.allfile()
    },
    //查看当前目录下的文件
    allfile() {
      //如果为空，则展示所有桶名
      if (this.nowdirectory == "") {
        this.listAllBuckets();
      }
      //否则，展示当前目录下文件
      else {
        this.listAllFileByDir(this.nowdirectory);
      }
    },
    // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.allfile()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.allfile()
      },
  },
};
</script>

<style></style>
